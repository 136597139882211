import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import { PageSeo } from "../components/PageSeo"
import "./styles/company.scss"

const PartnersTemplate = ({ data }) => {
  const { markdownRemark: markup } = data
  const {
    banner,
    aboutpartners,
    proven,
    collaboration,
    trainingcertificaion,
    types,
    partnersupport,
    technologypartners,
    trained,
    support,
    certificaion,
    becomeapartner,
  } = markup.frontmatter
  return (
    <Layout>
      <section className="PartnersTemplate">
        <Banner data={banner} />
        {/* <h2 className="text-center py-3">{name}</h2> */}
        {/* <h6 className="text-center pb-5">{aboutpartners.description}</h6> */}
        <AboutPartners aboutpartners={aboutpartners} />
        <Proven proven={proven} />
        <Collaboration collaboration={collaboration} />
        <Trainingcertificaion trainingcertificaion={trainingcertificaion} />
        <PartnersTypes types={types} />
        <PartnerSupport partnersupport={partnersupport} />
        <TechnologyPartners technologypartners={technologypartners} />
        <Trained trained={trained} />
        <Support support={support} />
        <Certificaion certificaion={certificaion} />
        <Becomeapartner becomeapartner={becomeapartner} />
      </section>
    </Layout>
  )
}
PartnersTemplate.propTypes = {
  aboutpartners: PropTypes.shape({
    description: PropTypes.string,
    heading: PropTypes.string,
    content: PropTypes.string,
  }),
}

const AboutPartners = ({ aboutpartners }) => {
  return (
    <div className="container aboutpartners text-center pt-3 pt-lg-5">
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <ReactMarkdown
            className="mb-3"
            children={aboutpartners.heading}
          ></ReactMarkdown>
          <ReactMarkdown children={aboutpartners.content}></ReactMarkdown>
        </div>
      </div>
    </div>
  )
}
AboutPartners.propTypes = {
  aboutpartners: PropTypes.shape({
    description: PropTypes.string,
    heading: PropTypes.string,
    content: PropTypes.string,
  }),
}

const Proven = ({ proven }) => {
  return (
    <div className="container mt-5">
      <h3 className="section_heading text-center py-3">{proven.title}</h3>
      <div className="row">
        {proven.blurb.length &&
          proven.blurb.map((item, index) => (
            <div key={`proven_${index}`} className="col-12 col-lg-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <ReactMarkdown children={item.content}></ReactMarkdown>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
Proven.propTypes = {
  title: PropTypes.string,
  blurb: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
    })
  ),
}

const Collaboration = ({ collaboration }) => {
  return (
    <div className="container mt-5">
      <h3 className="section_heading text-center py-3">
        {collaboration.title}
      </h3>
      <div className="row">
        {collaboration.blurb.length &&
          collaboration.blurb.map((item, index) => (
            <div
              key={`collaboration_${index}`}
              className="col-12 col-lg-4 mb-3"
            >
              <div className="card">
                <div className="card-body">
                  <p className="text-center m-0">{item.content}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
Collaboration.propTypes = {
  title: PropTypes.string,
  blurb: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
    })
  ),
}

const Trainingcertificaion = ({ trainingcertificaion }) => {
  return (
    <div className="container trainingcertificaion mt-5">
      <h3 className="section_heading text-center py-3">
        {trainingcertificaion.title}
      </h3>
      <div className="row justify-content-center">
        {trainingcertificaion.blurb.length &&
          trainingcertificaion.blurb.map((item, index) => (
            <div
              key={`trainingcertificaion_${index}`}
              className="col-12 col-lg-4 mb-3"
            >
              <div className="card h-100">
                <div className="card-img text-center pt-3">
                  <GatsbyImage
                    image={item.image.childImageSharp.gatsbyImageData}
                    alt={item.heading}
                  />
                </div>
                <div className="card-body">
                  {/* <h5 className="card-title text-center">{item.heading}</h5> */}
                  <p className="text-center m-0">{item.content}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
Trainingcertificaion.propTypes = {
  title: PropTypes.string,
  blurb: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      content: PropTypes.string,
    })
  ),
}

const PartnersTypes = ({ types }) => {
  return (
    <div className="partnerstype mt-4 py-3 py-lg-5 bg-light">
      <h1 className="section_heading text-center py-3">{types.title}</h1>
      <p className="text-center">{types.description}</p>
      <div className="container">
        <div className="row mt-4">
          {types.blurb.length &&
            types.blurb.map((item, index) => (
              <div className="col-lg-4 border-start mb-3" key={`types${index}`}>
                <div className="contentd">
                  <strong className="fs-6 text-muted">
                    {index < 9 ? "0" : ""}
                    {(item.index = index + 1)}
                  </strong>
                  <h5>{item.heading}</h5>
                  <p className="mt-4">{item.content}</p>
                </div>
                <div>
                  <Link
                    to="/company/become-partner"
                    className="mt-auto btn btn-sm btn-outline-primary"
                  >
                    Become A Partner
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
PartnersTypes.propTypes = {
  title: PropTypes.string,
  blurb: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      content: PropTypes.string,
    })
  ),
}

const PartnerSupport = ({ partnersupport }) => {
  return (
    <div className="container mt-4">
      <h1 className="section_heading text-center py-3">
        {partnersupport.title}
      </h1>
      <ReactMarkdown
        className="text-center"
        children={partnersupport.description}
      ></ReactMarkdown>
      <div>
        <ReactMarkdown children={partnersupport.content}></ReactMarkdown>
        <GatsbyImage
          image={partnersupport.image.childImageSharp.gatsbyImageData}
          alt={partnersupport.title}
          className=""
        />
      </div>
    </div>
  )
}
PartnerSupport.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const TechnologyPartners = ({ technologypartners }) => {
  return (
    <div className="container mt-4">
      <h4 className="section_heading text-center py-3">
        {technologypartners.title}
      </h4>
      <div className="container">
        <div className="row mt-4">
          {technologypartners.blurb.length &&
            technologypartners.blurb.map((item, index) => (
              <div className="col-4" key={`technologypartners${index}`}>
                <div className="card text-center border-0">
                  {/* <div className="card-header">
                    {item.heading}
                </div> */}
                  <div className="card-body">
                    <GatsbyImage
                      image={item.image.childImageSharp.gatsbyImageData}
                      alt={item.heading}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
TechnologyPartners.propTypes = {
  title: PropTypes.string,
  blurb: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })
  ),
}

const Trained = ({ trained }) => {
  return (
    <div className="bg-light">
      <div className="container mt-3 py-3">
        <h4 className="section_heading text-center py-3">{trained.title}</h4>
        <div className="row justify-content-center">
          {trained.blurb.length &&
            trained.blurb.map((item, index) => (
              <div key={`trained_${index}`} className="col-12 col-lg-4 mb-3">
                <div className="card h-100">
                  <div className="card-body">
                    <h5 className="card-title text-center">{item.heading}</h5>
                    <p className="text-center m-0">{item.content}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
Trained.propTypes = {
  title: PropTypes.string,
  blurb: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
    })
  ),
}

const Support = ({ support }) => {
  return (
    <div className="container support mt-4">
      <h4 className="section_heading text-center py-3">{support.title}</h4>
      <div className="row justify-content-center">
        {support.blurb.length &&
          support.blurb.map((item, index) => (
            <div key={`support_${index}`} className="col-12 col-lg-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title text-center">{item.heading}</h5>
                  <p className="text-center m-0">{item.content}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
Support.propTypes = {
  title: PropTypes.string,
  blurb: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
    })
  ),
}

const Certificaion = ({ certificaion }) => {
  return (
    <div className="bg-light">
      <div className="container mt-3 py-3">
        <h4 className="section_heading text-center py-3">
          {certificaion.title}
        </h4>
        <div className="row justify-content-center">
          {certificaion.blurb.length &&
            certificaion.blurb.map((item, index) => (
              <div
                key={`certificaion_${index}`}
                className="col-12 col-lg-4 mb-3"
              >
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-center">{item.heading}</h5>
                    <p className="text-center m-0">{item.content}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
Certificaion.propTypes = {
  title: PropTypes.string,
  blurb: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
    })
  ),
}

const Becomeapartner = ({ becomeapartner }) => {
  return (
    <div className="mt-4 becomepartner">
      <div className="container text-light py-3">
        <div className="row">
          <div className="col-12 col-lg-8 mb-3">
            <h3 className="section_heading py-3">{becomeapartner.title}</h3>
            <ReactMarkdown children={becomeapartner.content}></ReactMarkdown>

            <Link to={becomeapartner.url} className="btn btn-primary">
              {" "}
              Become a partner <i className="bi bi-arrow-right"></i>{" "}
            </Link>
          </div>
          <div className="col-12 col-lg-4 ml-auto mb-3 text-end">
            <GatsbyImage
              image={becomeapartner.image.childImageSharp.gatsbyImageData}
              alt={becomeapartner.title}
              className="rounded"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
Becomeapartner.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export const PartnersQuery = graphql`
  query PartnersQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        name
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        aboutpartners {
          description
          heading
          content
        }
        proven {
          title
          blurb {
            content
          }
        }
        collaboration {
          title
          blurb {
            content
          }
        }
        trainingcertificaion {
          title
          blurb {
            heading
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, height: 60, layout: CONSTRAINED)
              }
            }
            content
          }
        }
        types {
          title
          description
          blurb {
            heading
            content
          }
        }
        partnersupport {
          title
          description
          content
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        technologypartners {
          title
          blurb {
            heading
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, height: 100, layout: CONSTRAINED)
              }
            }
          }
        }
        trained {
          title
          blurb {
            heading
            content
          }
        }
        support {
          title
          blurb {
            heading
            content
          }
        }
        certificaion {
          title
          blurb {
            heading
            content
          }
        }
        becomeapartner {
          title
          url
          content
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, height: 350, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
export default PartnersTemplate
export const Head = () => (
  <PageSeo
    title="About Partners | INVESTMENT MANAGEMENT SOFTWARE SOLUTION PROVIDER"
    description="Credence Ananlytics partners with various teams to showcase its product offerings & capabilities"
    keywords="Proven solutions provider as your ally, Creative Collaboration, Training and Certification, The Credence Analytics Advantage"
  />
)
